<template>
  <div class="lean-improvement-jidoka-wrapper pt-2 pb-3" v-if="game">
    <div class="mb-2 has-text-centered">
      <b-button @click="allInspect" type="is-info" rounded
        >Allow all stations to correct defects</b-button
      >
    </div>
    <div
      class="is-divider divider-text-darker"
      data-content="Or customize for each station :"
    ></div>
    <div class="columns is-centered is-multiline">
      <div
        class="column is-3 has-text-centered"
        v-for="faci in facis"
        :key="faci.id"
      >
        <p class="label">
          {{ faci.name }}
          <a @click="removeFac(faci)">
            <b-icon type="is-danger" icon="delete" size="is-small"></b-icon>
          </a>
          :
        </p>
        <p>
          <b-field v-if="faci.params">
            <b-checkbox v-model="faci.params.stampRemove" size="is-small"
              >Can remove dots</b-checkbox
            >
          </b-field>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LeanImprovementJidoka",
  props: {
    game: Object
  },
  computed: {
    facis() {
      return Object.values(this.newFacilities).filter(fac => {
        return fac.type === "painting";
      });
    }
  },
  mounted() {
    this.initializeData();
  },
  methods: {
    initializeData() {
      this.readyWatch = false;
      this.newFacilities = JSON.parse(JSON.stringify(this.game.facilities));
      setTimeout(() => {
        this.readyWatch = true;
      }, 5);
    },
    allInspect() {
      this.facis.forEach(fac => {
        if (fac.params) {
          fac.params.stampRemove = true;
        }
      });
    },
    removeFac(faci) {
      this.$buefy.dialog.confirm({
        message: `This will permanently remove the station : <b>${faci.name}</b>`,
        cancelText: "Cancel",
        size: "is-medium",
        confirmText: "Remove",
        type: "is-danger",
        onConfirm: () => {
          this.$emit("update", { type: "balanceremove", obj: faci });
          setTimeout(() => {
            this.initializeData();
          }, 5);
        }
      });
    }
  },
  watch: {
    newFacilities: {
      handler: function() {
        if (this.readyWatch) {
          this.$emit("update", { type: "jidoka", obj: this.newFacilities });
        }
      },
      deep: true
    }
  },
  data() {
    return {
      readyWatch: false,
      newFacilities: {}
    };
  }
};
</script>

<style lang="scss">
.lean-improvement-changeover-wrapper {
}
</style>
